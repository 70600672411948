import { render, staticRenderFns } from "./ActivityTypeDelete.vue?vue&type=template&id=987d32a4&scoped=true&"
import script from "./ActivityTypeDelete.vue?vue&type=script&lang=js&"
export * from "./ActivityTypeDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "987d32a4",
  null
  
)

export default component.exports