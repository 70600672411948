<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="activityTypes.activityTypesData">
      <div class="row">
        <ActivityTypeCard
          v-for="(activityType, index) in activityTypes.activityTypesData"
          :key="index"
          :activityType="activityType"
          v-on:setActivityTypeData="activityTypes.activityType.fillData($event)"
        />
      </div>

      <ActionsData :actionsData="activityTypes.activityType" />
      <ActivityTypeInfo :activityTypeData="activityTypes.activityType" />
      <ActivityTypeDelete
        :activityTypeData="activityTypes.activityType"
        v-on:refresh="getAllActivityTypes()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="activityTypes.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <ActivityTypeFloatBtns
      :theFilterData="activityTypes.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ActionsData from "@/components/general/ActionsData.vue";
import ActivityTypeCard from "@/components/activityTypes/ActivityTypeCard.vue";
import ActivityTypeInfo from "@/components/activityTypes/ActivityTypeInfo.vue";
import ActivityTypeDelete from "@/components/activityTypes/ActivityTypeDelete.vue";
import ActivityTypeFloatBtns from "@/components/activityTypes/ActivityTypeFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import ActivityTypes from "@/models/activityTypes/ActivityTypes";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    ActivityTypeCard,
    ActivityTypeInfo,
    ActivityTypeDelete,
    ActivityTypeFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      activityTypes: new ActivityTypes(),
    };
  },
  methods: {
    changePage(page) {
      this.activityTypes.pagination.selfPage = page;
      this.getAllActivityTypes();
    },
    search(data) {
      this.activityTypes.filterData.fillData(data);
      this.activityTypes.activityTypesData = [];
      this.getAllActivityTypes();
    },
    async getAllActivityTypes() {
      this.isLoading = true;
      try {
        const response =
          await this.activityTypes.activityType.getAllActivityTypes(
            this.language,
            this.userAuthorizeToken,
            this.activityTypes.pagination,
            this.activityTypes.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;

          this.activityTypes.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.activityTypes.activityTypesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.activityTypes.activityTypesData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.activityTypes.activityTypesData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.activityTypes.activityTypesData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllActivityTypes();
  },
};
</script>
