import { render, staticRenderFns } from "./ActivityTypeInfo.vue?vue&type=template&id=5aadd05c&scoped=true&"
import script from "./ActivityTypeInfo.vue?vue&type=script&lang=js&"
export * from "./ActivityTypeInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aadd05c",
  null
  
)

export default component.exports