<template>
  <b-modal id="ActivityTypeInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/activity-types.svg" class="icon-lg" />
        {{ $t("activityTypes.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityTypeData.activityTypeNameAr"
        :title="$t('activityTypes.nameAr')"
        :imgName="'activity-types.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityTypeData.activityTypeNameEn"
        :title="$t('activityTypes.nameEn')"
        :imgName="'activity-types.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityTypeData.activityTypeDescriptionAr"
        :title="$t('activityTypes.descriptionAr')"
        :imgName="'activity-types.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityTypeData.activityTypeDescriptionEn"
        :title="$t('activityTypes.descriptionEn')"
        :imgName="'activity-types.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityTypeData.fullCode"
        :title="$t('code')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityTypeData.activityTypeNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { timeToLang } from "@/utils/functions";

export default {
  name: "ActivityTypeInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["activityTypeData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
};
</script>

<style scoped lang="scss"></style>
